import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronPlugIn from "../../components/jumbo/JumbotronPlugIn"
import Devotional from "../../assets/devotional.jpeg"
import "./PlugIn.css"

export const PlugIn = () => (
  <React.Fragment>
    <div className="plugInBack">
    <NavigationBar />
    <JumbotronPlugIn />
    <Container>
        <h2 className="cardText center">Plug In</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          While my computer is fully lit and functioning properly, it is just a matter of time before it gradually starts losing its full 
          capabilities because it is not plugged in. In order for the computer to perform at its optimal level or download any upgrades, it 
          must be connected to a power source. I have often been caught without my power cord, and within hours, my computer screen shuts down 
          because of depleted power. If I wish to continue writing, I must connect it back to the power source.  
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          Just like the unplugged computer, if we stay away from our spiritual power source, we will deteriorate. We were not designed to handle 
          the pressures of this world on our own. Christ says, “Apart from me, you can do nothing” (John 15:5). We must be intentional about staying 
          connected to Christ through Scripture, prayer, meditation, and godly relationships.
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Take 5 uninterrupted minutes and spend time reading Scripture, praying, and meditating on what the Lord is saying.  
        </div>

        <br />

    </Container>

    </div>
  </React.Fragment>
)