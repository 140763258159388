import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import ReactPlayer from "react-player";
import "./About.css";
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'
import { NavigationBar } from "../components/nav_footer/NavigationBar";
import Zoom from 'react-reveal/Zoom';
import Fade from "react-reveal/Fade";
import Preach from "../assets/preach.jpg";
import James from "../assets/james.jpg";
import WhiteCircle from "../assets/white circle.png";
import LightGreyCircle from "../assets/lightGreyCircle.png";
import BlackCircle from "../assets/black circle.png";
import RedCircle from "../assets/red circle.png";
import Together from "../assets/AdobeStock_236751935.jpg";
import Community from "../assets/community.jpg";
import JamesAndWife from "../assets/jamesandwife.jpg";
import BurgCircle from "../assets/burg circle.png";
import DullRedCircle from "../assets/dull red circle.png"
import DullBurgCircle from "../assets/dull burg circle.png"
import PinkCircle from "../assets/pink circle.png"
import { Footer } from "../components/nav_footer/Footer"


export const About = () => (
  <div>
    <div className="greyBack">
      <NavigationBar className="front" />
      <Container className="fitPage" fluid="true">

        <Image className="circle1" src={WhiteCircle} />
        <Image className="circle2" src={LightGreyCircle} />

        <br />
        <br />

        <Fade>
          <h1 className="front alignCenter">About Me</h1>
        </Fade>

        <br />

        <Row className="front">
          <Col xs={12} md={4}> 
            <Fade left>
              <Image className="aboutPhoto" src={Preach} />
            </Fade>
          </Col>

          <Col xs={12} md={8} className="alignRight red front">
            <Fade right>
              <div className="fontSize">
                James L. Brooks is committed to listening and responding
                to the people who have been hurt and marginalized by society.
                He serves as the Senior Pastor of <a className="linksAbout" href="https://hcc1908.org/">Harmony Community Church</a> and
                the Vice President of Mission and Community Engagement at Lawndale
                Christian Health Center on the Westside of Chicago. He formerly served
                as the Coordinator of Youth Ministry at Grace Lutheran Church in River
                Forest, IL, and was the first African American to be called to serve
                this historic Lutheran congregation.
              </div>
            </Fade>
          </Col>
        </Row>
        <Fade top>

        <Parallax className="circle3" y={[40, -100]}  tagOuter="figure">
        <Image className="circle3Width" src={BlackCircle} />
</Parallax>
        </Fade>
        <br />
        <br />


        <h1 className="alignCenter white front">Graduate</h1>

        <br />
        <Row>
          <Col xs={12} md={8} className="white">
            <div className="fontSize front">

              James Brooks is a summa cum laude graduate of Concordia University
              where he majored in organizational <Image className="circle4 back" src={RedCircle} />management. He holds a Master of
              Divinity degree from Northern Theological Seminary, where he was the
              first Dr. John Perkins Scholar graduate. Reverend Brooks also completed
              his youth and theology certification at Princeton Theological Seminary
              and a clinical pastoral education unit at the University of Illinois
              Medical Center. To add to his wide array of empowering strategies and
              techniques, he became a John Maxwell Certified Coach in 2014.

          </div>
          </Col>

          <Col xs={12} md={4} className="alignRight">
            <Image src={DullRedCircle} className="circle5" />
            <Fade right>
              <Image className="aboutPhoto" src={James} />
            </Fade>

          </Col>
        </Row>
      </Container>
      <br />
    </div>

    <ParallaxBanner
      className="front"
      layers={[
        {
          image: '../../images/help.jpg',
          amount: .3,
        },
      ]}
      style={{
        height: '500px',
      }}
    >

    </ParallaxBanner>
    <div className="whiteBack">
      <Container className="fitPage" fluid="true">
        <br />
        <br />
        <Zoom>

          <Image className="circle6" src={BurgCircle} />
        </Zoom>
        <Fade>

          <h1 className="alignCenter">Life Coach</h1>
        </Fade>
        <br />
        <br />
        <Row>

          <Col xs={12} md={4}>
            <Fade bottom>

              <Image className="aboutPhoto" src={Together} />
            </Fade>

          </Col>
          <Col xs={12} md={8}>
            <Fade bottom>

              <div className="fontSize front black">
                For his service, James has received numerous awards and accolades,
                including being a recipient of Northern Seminary’s distinguished
                Bryan F. Archibald Award for Excellence in preaching.
                Reverend Brooks, or Pastor J as his congregants call him, is grateful
                to participate in God’s work of proclaiming liberty to the oppressed,
                giving hope to the hopeless, and encouraging the faithful.
                He enthusiastically facilitates workshops in leadership development,
                spiritual resiliency, margins, boundaries, and Christian community
                development.
        </div>
            </Fade>


          </Col>
        </Row>

        <br />
        <br />
        <Fade>
          <h1 className="alignCenter">Community Developer</h1>
        </Fade>
        <Fade left>

          <Image className="circle7" src={LightGreyCircle} />
        </Fade>
        <br />

        <Row>
          <Col xs={12} md={8}>

            <div className="fontSize front burgundy">
              He sits on various boards for institutions and organizations within his
              community, including North Lawndale College Prep High School, Chicago Bible
              Society and the Lawndale Christian Legal Clinic. He believes in serving with
              excellence in every field of human endeavor.

          </div>
          </Col>
          <Col xs={12} md={4} className="alignRight">
            <Zoom>

              <Image className="aboutPhoto" src={Community} />
            </Zoom>
          </Col>
        </Row>
        <Image src={DullBurgCircle} className="circle8" />
        <br />

        <br />
        <Fade>

          <h1 className="alignCenter">Family Man</h1>
        </Fade>
        <br />
        <Row>
          <Col>
            <Zoom>

              <div className="fontSize front alignCenter white">
                James is currently a doctoral student at Northern Theological Seminary. He is
                happily married to his beautiful wife, Jacqueline, a Registered Nurse specializing
                in transplant care. They have two brilliant and energetic daughters, Jaylah and
                Janay.
          </div>
            </Zoom>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="alignCenter front">
            <Fade bottom>

              <Image className="aboutPhoto2" src={JamesAndWife} />
            </Fade>

          </Col>


          {/* <Parallax className="custom-class" y={[-100, 50]} tagOuter="figure"> */}
          <Image src={PinkCircle} className="circle9" />
          {/* </Parallax> */}
        </Row>

      </Container>
      {/* <Footer/> */}
    </div>
  </div>
)