import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import ReactPlayer from "react-player"
import { NavigationBar } from '../components/nav_footer/NavigationBar'

export const VideoBlog = () => (
  <React.Fragment>
    <NavigationBar/>
    <Container>
      <div>video blog</div>
    </Container>
  </React.Fragment>
)