import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronFurnace from "../../components/jumbo/JumbotronFurnace"
import Devotional from "../../assets/devotional.jpeg"
import "./Furnace.css"


export const Furnace = () => (
  <React.Fragment>
    <div className="furnaceBack">
    <NavigationBar />
    <JumbotronFurnace />
    <Container>
        <h2 className="cardText center">Furnace</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          It was the coldest day of the year. While in a meeting, I received a text from my wife asking me to come home right away because
           the furnace wasn't working. Immediately, I asked to be excused and raced home. I tried every little trick I knew to jumpstart 
           the furnace, but nothing worked. It was getting colder, and Chicago weather doesn’t play, so I placed calls to family and friends. 
           However, no one could help. 

          Finally, I called the heating repair guy, and he arrived within the hour. He instantly identified the problem, and within five 
          minutes, the house started to warm up again. When I asked him how he fixed it so quickly, he replied, "I'm a former employee of 
          the manufacturer of these furnaces, I know these things inside and out."
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>
        
        <br />
        <br />
        <br />

        <p className="devText">
          How often in crisis moments do we try to figure things out on our own? How often do we call a friend or relative that has no clue 
          how to help us through the situation? The Lord wants to be our first option. He says, "Call on me in the day of trouble; I will deliver 
          you, and you will honor me" (Psa 50:15). God is our divine manufacturer; He knows us better than we know ourselves. So when the flames 
          of your spirit are running low and the fire seems to be dwindling, I’ve got a guy. His name is Jesus.
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Take a moment to consider what things you’re trying to fix yourself, and surrender them to God. If you’re a verbal processor, try journaling 
          your thoughts and prayers.
        </div>

        <br />

    </Container>

    </div>
  </React.Fragment>
)