import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronThankfulChair from "../../components/jumbo/JumbotronThankfulChair"
import Devotional from "../../assets/devotional.jpeg"
import "./ThankfulChair.css"

export const ThankfulChair = () => (
  <React.Fragment>
    <div className="chairBack">
      
    <NavigationBar />
    <JumbotronThankfulChair />
    <Container>
        <h2 className="cardText center">Thankful Chair</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          There is a chair in the family room of my house that I call the thankful chair.  It’s a black rocking chair that my wife, Jackie, 
          surprised me with years ago. The chair sits right where my father’s chair used to sit. Every time I see the chair, it triggers thankfulness 
          for the man who raised me to know the Lord. When I sit in the chair, I begin to thank God for all that He has done for me.  
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          Thankfulness is not only a good practice for your mental health but also a way to show your appreciation to your Redeemer, who has 
          put in a lot of work over time to demonstrate his undying love for you. From Old Testament to New, Biblical writers demonstrated this for us.
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Do you have an item that evokes fond memories or reminds you of a trouble from which God redeemed you? Make an effort to direct your thankfulness 
          to God whenever you see or touch that object. You can also find a small rock to keep in your pocket or purse as a remembrance token, praying and 
          thanking God whenever you touch it.
        </div>

        <br />

    </Container>
    </div>
  </React.Fragment>

)