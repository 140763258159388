import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { About } from "./screens/About";
import { Contact } from "./screens/Contact";
import { NoMatch } from "./screens/NoMatch";
import { Front } from "./screens/Front";
import { Blog } from "./screens/Blog";
import { VideoBlog } from "./screens/VideoBlog";
import { BaggageBlog } from "./screens/Blog Screens/BaggageBlog";
import { Furnace } from "./screens/Blog Screens/Furnace";
import { PlugIn } from './screens/Blog Screens/PlugIn';
import { SaltTruck } from "./screens/Blog Screens/SaltTruck";
import { ThankfulChair } from "./screens/Blog Screens/ThankfulChair";
import { Bozo } from "./screens/Blog Screens/Bozo";
import { FallBack } from "./screens/Blog Screens/FallBack";
import { Ladder } from "./screens/Blog Screens/Ladder";
import { OneStep } from "./screens/Blog Screens/OneStep";
import { Footer } from "./components/nav_footer/Footer";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Front} />
          <Route path="/about" component={About} />
          <Route path="/blog" component={Blog} />
          <Route path="/videoblog" component={VideoBlog} />
          <Route path="/contact" component={Contact} />
          <Route path="/baggage" component={BaggageBlog} />
          <Route path="/furnace" component={Furnace} />
          <Route path="/plugin" component={PlugIn} />
          <Route path="/salttruck" component={SaltTruck} />
          <Route path="/thankfulchair" component={ThankfulChair} />
          <Route path="/bozo" component={Bozo} />
          <Route path="/fallback" component={FallBack} />
          <Route path="/ladder" component={Ladder} />
          <Route path="/onestep" component={OneStep} />
<Route component={NoMatch} />
        </Switch>
        {/* <Footer/> */}
      </Router>
    </React.Fragment>
  );
}

export default App;
