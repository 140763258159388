import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronFallBack.css"

const JumbotronFallBack = () => (
  <Jumbo fluid className="jumboFall">

  </Jumbo>
)

export default JumbotronFallBack;