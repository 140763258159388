import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import JumbotronContact from '../components/jumbo/JumbotronContact'
import { NavigationBar } from '../components/nav_footer/NavigationBar'
import "./Contact.css"
import James from "../assets/james.jpg";
import BlogPic from "../assets/blogPic.png"
import { Footer } from '../components/nav_footer/Footer'
import { Parallax, ParallaxBanner } from 'react-scroll-parallax'
import WhiteCircle from "../assets/white circle.png";




export const Contact = () => (
    <div className="contactBackground">
    <NavigationBar/>
    <JumbotronContact/>
    <Container>
      <Row>
        <Col xs={12} md={6} className="front">
          <h2 className="cardText center">I am available to you. Lets talk.</h2>
          <div className="font center">
            I'd love to talk to you. If you have any questions about me, the services I 
            provide, or just need someone to talk to, please feel free to reach out to me on 
            any of the platforms available below.

            <br />
            <br />

            <div className="contactButtons">

              <form action="https://hcc1908.org/contact/" target="_blank" className="center">
                <Button className="buttonColor" variant="danger" type="submit">Harmony Community Church</Button>
              </form>

              <br/>

              <Row>
                <Col className="center">
                  <form action="https://www.instagram.com/jameslbrooks23" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">Instagram</Button>
                  </form>

                  <br/>

                  <form action="https://www.facebook.com/james.brooks.9421" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">Facebook</Button>
                  </form>

                  <br/>

                  <form action="https://lawndale.org/leadership" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">Lawndale CHC</Button>
                  </form>
                </Col>

                <Col className="center">
                  <form action="http://linkedin.com/in/james-l-brooks-85b7a182" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">LinkedIn</Button>
                  </form>

                  <br />

                  <form action="https://twitter.com/jameslbrooks23?s=21" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">Twitter</Button>
                  </form>

                 <br/>

                  <form action="mailto:theology23@gmail.com" target="_blank">
                    <Button className="buttonColor" variant="danger" type="submit">Email Me</Button>
                  </form>
                </Col>
              </Row>

              <br/>
            </div>
          </div>
        </Col>

        <Col xs={0} md={6}>
          <Image className="contactPhoto" src={BlogPic} />
        </Col>
      </Row>
    </Container>
    </div>

)