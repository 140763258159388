import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronBozo from "../../components/jumbo/JumbotronBozo"
import Devotional from "../../assets/devotional.jpeg"

import "./Bozo.css"

export const Bozo = () =>(
  <React.Fragment>
    <div className="bozoBack">
    <NavigationBar />
      <JumbotronBozo />
    <Container>
        <h2 className="cardText center">Bozo</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          Bozo the clown was one of my favorite toys as a kid. Using my imagination, I could make him say or be whomever I wanted. No 
          matter what was thrown at him, he bounced back due to his low center of gravity, which meant I could let out all my childish 
          energy. Being a boxing toy, he was built to endure rough play and could handle all the punches. 

          Bozo was a red, white, and blue symbol of the resilience my own community faced in the fun form of a toy clown.
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          Jesus also faced a lot of punches in the form of family rejection, threats on his life, whippings, shaming, and ultimately death. 
          Up until his crucifixion, he evaded his enemies’ schemes, continuing on with his ministry in spite of the dangers. Even after he 
          was captured and killed, however, he bounced back by resurrecting from death. He bounced back to life because he had the power of God 
          within him.

          As Christians, we too have the power of God in us through His Spirit. We have Christ on our side, his Spirit within us, and the Father 
          protecting us. Jesus never promised us easy lives, but in John 16:33, he tells his disciples to take heart, for he has overcome the world.
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Thank God for the ways He has protected and provided for you. Read John 14-17 to be strengthened by the words Christ said to his disciples before 
          going to the cross.
        </div>

        <br />

    </Container>
    </div>
  </React.Fragment>
)
