import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card, Jumbotron as Jumbo } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronBaggage from "../../components/jumbo/JumbotronBaggage"
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import Devotional from "../../assets/devotional.jpeg"
import Exercise from "../../assets/exercise.jpg"
import "./Baggage.css"

export const BaggageBlog = () => (
  <React.Fragment className="baggageBack">
    <div className="baggageBack">
      <NavigationBar />
      <JumbotronBaggage />
      <Container>
        <h2 className="cardText center">Baggage Check</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          Recently, I had to take a quick flight to Minnesota. Prior to entering the airport security line, 
          I saw a sign that read, "Baggage Check." This is where passengers check bulky baggage before they are clear to fly. 
          Did you catch that? If you're going to fly, you must relinquish your excess baggage. In essence, if you're going to 
          go higher, you have to let some things go!
        </p>

        <br/>
        <br />
        
        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br/>
        <br />
        <br />

        <p className="devText">
          What "baggage" remains unchecked in your life, preventing you from going higher? Is it the baggage of 
          unforgiveness, past failures, disappointments, bad relationships, feeling inadequate, unconfessed sin, 
          or something else? The author of Hebrews writes, "Lay aside every weight and sin which clings so closely, 
          and let us run with endurance the race that is set before us" (Heb 12:1).

          The good news is that we serve a God who is willing to handle our baggage. Just give it to him! Peter 
          tells us to be humble under the mighty hand of God so that "in the proper time He may take you higher, 
          casting all anxieties on Him, because He cares for you" (1 Pet 5:6).
        </p>

        <br />
        <br />

        <div  className="exercise">
          <div className="exerciseOverlay"></div>
          
          <h1 className="centered">Excercise</h1>
        </div>

        <br/>

        <div className="devText">
          Take a piece of paper and draw a spacious rectangular suitcase. Inside of it, list the things that are weighing 
          you down. Address what needs to be fixed and give everything over to God by lighting the paper on fire or 
          shredding it to pieces. 
        </div>

        <br />

      </Container>
    </div>
  </React.Fragment>
)