import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronThankfulChair.css"

const JumbotronThankfulChair = () => (
  <Jumbo fluid className="jumboThankfulChair">

  </Jumbo>
)

export default JumbotronThankfulChair;