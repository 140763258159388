import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronOneStep.css"

const JumbotronOneStep = () => (
  <Jumbo fluid className="jumboOneStep">

  </Jumbo>
)

export default JumbotronOneStep;