import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronFallBack from "../../components/jumbo/JumbotronFallBack"
import Devotional from "../../assets/devotional.jpeg"
import "./FallBack.css"

export const FallBack = () => (
  <div className="fallBack">

    <React.Fragment>
      <div className="bozoBack">
        <NavigationBar />
        <JumbotronFallBack />
        <Container>
          <h2 className="cardText center">Fall Back</h2>
          <h3 className="obj">Object Lesson</h3>
          <p className="devText">
            Have you ever been to a summer camp, training week, or business retreat where the leader tells you to pair up and 
            then do trust falls? As the falling person, you have to trust that the person behind you is strong enough to hold 
            your weight. You have to trust that they are equipped with enough agility and muscle tone to intercept your fall 
            without injuring anyone. You have to trust that they won’t duck out in fear but will be present to catch you and 
            break your fall.
          </p>

          <br />
          <br />

          <div className="devotional">
            <div className="devotionalOverlay"></div>

            <h1 className="centered">Devotional</h1>
          </div>

          <br />
          <br />
          <br />

          <p className="devText">
            At the start of Isaiah 43, God tells his people not to fear because He is with them in every stormy situation, and He 
            will help them pass through. Jesus Christ, God in the flesh, proved this time and time again as he spent time listening 
            to hurting people and healing their diseases. It’s even been verified that he defeated death (1 Cor 15:1-8)! Though we 
            may not know the outcome of our troubles, we can trust that Christ will catch us when we fall back into his arms.
          </p>

          <br />
          <br />

          <div className="exercise">
            <div className="exerciseOverlay"></div>

            <h1 className="centered">Excercise</h1>
          </div>

          <br />

          <div className="devText">
            Find somebody in your house and ask them to do a physical trust fall with you. Then open a genuine conversation about trusting 
            God. Do they trust him with their eternity? Do you trust him with your current situation? Share the ways God has been faithful 
            as an exercise in trusting him once more.
          </div>

          <br />

        </Container>
      </div>
    </React.Fragment>
  </div>


)

