import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../components/nav_footer/NavigationBar'
import { Link } from "react-router-dom";


export const NoMatch = () => {



  return (
    <div>
      404 not found
    </div>
)


}

  
