import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronSaltTruck.css"

const JumbotronPlugIn = () => (
  <Jumbo fluid className="jumboSaltTruck">

  </Jumbo>
)

export default JumbotronPlugIn;