import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../components/nav_footer/NavigationBar'
import { Link } from "react-router-dom";
import James from "../assets/james.jpg";
import Jumbotron from "../components/jumbo/Jumbotron";
import "./Blog.css";
import BlogPic from "../assets/blogPic.png"
import BaggageCropped from "../assets/Baggage Cropped.jpg"
import FurnaceCropped from "../assets/furnace Cropped.jpg"
import LowBattery from "../assets/lowBattery.jpeg"
import SaltTruckCropped from "../assets/saltTruckCropped.jpg"
import ChairCopped from "../assets/chairCropped.jpg"
import ClownCopped from "../assets/clownCropped.jpg"
import FallCropped from "../assets/fallCropped.jpg"
import LadderCropped from "../assets/ladderCropped.jpg"
import stepCropped from "../assets/stepCropped.jpg"



export const Blog = () => (
  <React.Fragment>
    <div className="blogBack">

    <NavigationBar />
    <Jumbotron />
    <br />
    <Container>
      <Row>
        <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
            <Link className="linkBack" to="/baggage">

              <Card.Img variant="top" src={BaggageCropped} />
            </Link>
            <Card.Body>
              <Card.Title className="center cardText">Baggage Check</Card.Title>
                <Card.Text className="cardText">
                If you're going to go higher, you have to let some things go.
              </Card.Text>
              <Link className="center" to="/baggage">
                  <Button variant="danger" className="buttonColor buttonColorGlow" type="submit" >Read more...</Button>
              </Link>

            </Card.Body>
          </Card>      </Col>
          <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
            <Link to="/furnace">
              <Card.Img variant="top" src={FurnaceCropped} />
            </Link>
            <Card.Body>
                <Card.Title className="cardText">Furnace</Card.Title>
                <Card.Text  className="cardText">
                The Lord wants to be our first option. Is He yours?
              </Card.Text>
                <Link className="center" to="/furnace">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
              </Link>

            </Card.Body>
          </Card>      </Col>
          <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
            <Link to="/plugin">
              <Card.Img variant="top" src={LowBattery} />
            </Link>
            <Card.Body>
                <Card.Title className="cardText" >Plug In</Card.Title>
                <Card.Text className="cardText" >
                We must be intentional about staying connected to Christ.
              </Card.Text>
                <Link className="center" to="/plugin">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
              </Link>

            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
          <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
              <Link to="/salttruck">
                <Card.Img variant="top" src={SaltTruckCropped} />
              </Link>
            <Card.Body>
              <Card.Title className="cardText">Salt Truck</Card.Title>
              <Card.Text className="cardText">
                God, who is rich in mercy, provides makes a way out darkness.             
              </Card.Text>
                <Link className="center" to="/salttruck">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
              </Link>

            </Card.Body>
          </Card>
        </Col>

          <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
            <Link to="/thankfulchair">
            <Card.Img variant="top" src={ChairCopped} />
            </Link>
            <Card.Body>
              <Card.Title className="cardText">Thankful Chair</Card.Title>
              <Card.Text className="cardText">
                We need thankfulness for our mental health, and our relationship with God.                  
              </Card.Text>
                <Link className="center" to="/thankfulchair">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
              </Link>

            </Card.Body>
          </Card>
        </Col>

          <Col xs={12} md={4} className="center">
          <Card style={{ width: '100%' }}>
            <Link to="/bozo">
            <Card.Img variant="top" src={ClownCopped} />
            </Link>
            <Card.Body>
              <Card.Title className="cardText">Bozo the Clown</Card.Title>
              <Card.Text className="cardText">
                Life hits hard. How well can you bounce back?
              </Card.Text>
                <Link className="center" to="/bozo">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
              </Link>

            </Card.Body>
          </Card>
        </Col>
      </Row>
<br/>
        <Row>
          <Col xs={12} md={4} className="center">
            <Card style={{ width: '100%' }}>
              <Link to="/fallback">
                <Card.Img variant="top" src={FallCropped} />
              </Link>
              <Card.Body>
                <Card.Title className="cardText">Fall Back</Card.Title>
                <Card.Text className="cardText">
                  Christ will catch us when we fall back into his arms.
              </Card.Text>
                <Link className="center" to="/fallback">
                  <Button variant="danger" className="buttonColor" type="submit" >Read more...</Button>
                </Link>

              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} className="center">
            <Card style={{ width: '100%' }}>
              <Link to="/ladder">
                <Card.Img variant="top" src={LadderCropped} />
              </Link>
              <Card.Body>
                <Card.Title className="cardText">Ladder</Card.Title>
                <Card.Text className="cardText">
                  We are called to give to those in need. Love is the answer.
              </Card.Text>
                <Link className="center" to="/ladder">
                  <Button variant="danger" className="buttonColor" type="submit">Read more...</Button>
                </Link>

              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4} className="center">
            <Card style={{ width: '100%' }}>
              <Link to="/onestep">
                <Card.Img variant="top" src={stepCropped} />
              </Link>
              <Card.Body>
                <Card.Title className="cardText">One Step</Card.Title>
                <Card.Text className="cardText">
                  The journey is a long one, but you will arrive if you stick with God.
              </Card.Text>
                <Link className="center" to="/onestep">
                  <Button variant="danger" className="buttonColor" type="submit">Read more..</Button>
                </Link>

              </Card.Body>
            </Card>
          </Col>
        </Row>
    </Container>
    </div>

  </React.Fragment>
)