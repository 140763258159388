import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronFurnace.css"

const JumbotronFurnace = () => (
  <Jumbo fluid className="jumboFurnace">

  </Jumbo>
)

export default JumbotronFurnace;