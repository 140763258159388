import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronContact.css"

const JumbotronContact = () => (
  <Jumbo fluid className="jumboContact">
    <div className="overlay"></div>
    <Container>
      <h1 className="h1White center">Reach Out</h1>
      <br/>
      <p className="center">Ephesians 4:2 
        <br/>
        With all humility and gentleness, with patience, bearing with one another in love,
        </p>
    </Container>
  </Jumbo>
)

export default JumbotronContact;