import React from 'react'
import { Container, Row, Col, Image, } from "react-bootstrap"
import { Link } from "react-router-dom"
import TransJames from "../assets/transparentJames.png"
import "./Front.css"

export const Front = () => (
  <React.Fragment>

      <Container >
        <Row>
      
        <Col>
          <div className="verticalAdjust">
          <h1>James L. Brooks</h1>
            <Link className="red" to="/about"><h1>Welcome</h1></Link>
         
        </div>
        </Col>

        <Col>

        </Col>
      
      </Row>
      </Container>
    
 

    {/* <Container className="background" fluid="true"> */}
    {/* <Row className="fixed-bottom">
        <Col sm={12}>
        <Link to="/about">Welcome</Link>
        </Col>
        <Col>
        <Image className="frontPic sticky-bottom" src={TransJames}/>
        </Col>
      </Row> */}
    {/* </Container> */}
  </React.Fragment>
)