import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronLadder.css"

const JumbotronLadder = () => (
  <Jumbo fluid className="jumboLadder">

  </Jumbo>
)

export default JumbotronLadder;