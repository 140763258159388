import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronOneStep from "../../components/jumbo/JumbotronOneStep"
import Devotional from "../../assets/devotional.jpeg"
import "./OneStep.css"

export const OneStep = () => (
  <React.Fragment>
    <div className="oneStepBack">
      <NavigationBar />
      <JumbotronOneStep />
      <Container>
        <h2 className="cardText center">One Step</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          One summer, my assistant rolled her foot while heading down the stairs to the train. You could see her hobbling around Lawndale 
          on crutches for a few weeks as she regained strength and mobility. She started off slowly, moving one step at a time, putting one 
          foot in front of the other, motivating herself under her breath as the hot sun made her sweat. Traveling from the bus to the 
          air-conditioned cafe seemed like an eternity, while for able-bodied customers it only took a few minutes. Still, she made every block 
          an achievement and celebrated the road markers as she headed towards her goal.
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          You may not be where you want to be in life, but you are not where you used to be. Maybe God has called you to a certain career or location, 
          but you’re still stuck in the same low-wage job. You’ve been preparing, educating yourself, praying, but you haven’t been able to move on yet.
<br/>
          <br />
          The Israelites faced the same thing: God promised them a land of their own, but they were still stuck in the wilderness when their leader Moses
           died. But God spoke to them in Joshua 1:7-9 and encouraged them, saying he would still be with them. If they obeyed his Word, they would succeed. 
           They weren’t living in their new home yet, but they were much closer than they had been, and they would soon arrive if only they’d stick with God. 

        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Journal about your hopes, about what God has made known to you, and about the steps you’ve taken. What is God saying to you now?
        </div>

        <br />

      </Container>
    </div>
  </React.Fragment>
)