import React from 'react'
import { Nav, Navbar, Image } from "react-bootstrap";
import { Link } from "react-router-dom"
import './Navbar.css'


export const NavigationBar = () => (


        <Navbar collapseOnSelect="true" expand="lg" className="front borders">
            <Navbar.Brand><Link className="link navLinks" to="/about"> James L. Brooks</Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="light">
                <Nav className="ml-auto">
                <Nav.Item><Nav.Link className="link navItem navLinks" href="/about"><Link className="link navItem navLinks" to="/about">About</Link></Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="/blog"><Link className="link navItem navLinks" to="/blog">Blog</Link></Nav.Link></Nav.Item>
                    {/* <Nav.Item><Nav.Link href="/videoblog"><Link className="link navItem navLinks" to="/videoblog">Video Blog</Link></Nav.Link></Nav.Item> */}
                {/* <Nav.Item><Nav.Link href="/services"><Link className="link navItem navLinks" to="/services">Life Coaching</Link></Nav.Link></Nav.Item> */}
                    <Nav.Item><Nav.Link href="/contact"><Link className="link navItem navLinks" to="/contact">Contact Me</Link></Nav.Link></Nav.Item>

                </Nav>
            </Navbar.Collapse>
        </Navbar>


)
