import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronSaltTruck from "../../components/jumbo/JumbotronSaltTruck"
import Devotional from "../../assets/devotional.jpeg"
import "./SaltTruck.css"

export const SaltTruck = () => (
  <React.Fragment>
    <div className="saltBack">
      <NavigationBar />
      <JumbotronSaltTruck />
      <Container>
        <h2 className="cardText center">Salt Truck</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          One Christmas, my wife and I traveled to pick up our daughter from the University of Missouri. We were heading south on I-55, riding on black 
          ice, thick slush splashing on the windshield. Cars lay in ditches, and snow poured out of the sky like grits pouring out of the pot. It was 
          one of the scariest road trips I'd ever experienced. The storm grew progressively worse as we inched our way back home. My dad called Jackie's 
          phone to check in on us and make sure we were okay. She put him on speakerphone, and he begged us to take our time, reminding us that the storm 
          would pass after awhile. He ended the call by saying, "It will be okay; God will see y'all through." I must admit, it was comforting to hear 
          my dad's voice and to know that he was thinking about and praying for us. We hung up, and about two miles later, we saw a salt truck merging 
          on the highway. Its flashing lights brought us peace, and we began to shout, "Thank you, Jesus!" We drove behind the truck for miles, making 
          our way through the blizzard on a salted road and a bright path.
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          Storms of life will rage, and at times your vision will be cloudy. Sometimes it will be hard to see the road ahead. But God, who is rich in mercy, 
          will always make provision for us to be led out of the darkness. This is why David says, "The Lord is my light and my salvation. Why should I 
          be afraid?" (Psalm 27:1)
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Meditate on Psalm 27 and choose one verse from the chapter to commit to memory.
        </div>

        <br />

      </Container>
    </div>
  </React.Fragment>
)