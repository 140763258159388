import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronBaggage.css"

const JumbotronBaggage = () => (
  <Jumbo fluid className="jumboBag">
    {/* <div className="overlay"></div> */}
  </Jumbo>
)

export default JumbotronBaggage;