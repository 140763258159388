import React from 'react';
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import "./JumbotronPlugIn.css"

const JumbotronPlugIn = () => (
  <Jumbo fluid className="jumboPlugIn">

  </Jumbo>
)

export default JumbotronPlugIn;