import React from 'react'
import { Container, Row, Col, Image, Button, Accordion, Card } from "react-bootstrap"
import { NavigationBar } from '../../components/nav_footer/NavigationBar'
import JumbotronLadder from "../../components/jumbo/JumbotronLadder"
import Devotional from "../../assets/devotional.jpeg"
import "./Ladder.css"

export const Ladder = () => (
  <React.Fragment>
    <div className="ladderBack">
      <NavigationBar />
      <JumbotronLadder />
      <Container>
        <h2 className="cardText center">Ladder</h2>
        <h3 className="obj">Object Lesson</h3>
        <p className="devText">
          The flames glinted through the downstairs windows, slowly melting them as the heat and smoke spread throughout the house. 
          J’nae waved out of her bedroom window on the second story, dancing to keep her feet from burning on hot floor, and waited 
          anxiously for the ladder to reach the roof. A soft thud signaled its arrival. Then the fire fighter ushered her up the 
          ladder to safety.
        </p>

        <br />
        <br />

        <div className="devotional">
          <div className="devotionalOverlay"></div>

          <h1 className="centered">Devotional</h1>
        </div>

        <br />
        <br />
        <br />

        <p className="devText">
          If you see someone in need and have the capacity to help, the loving thing to do is to give of what you have. Jesus did this in 
          all his actions and messages on earth — think about the story of the Good Samaritan, for example — and then by giving up his 
          very life so we might live eternally.

          We can show love for others by sharing meals, meeting physical needs, and helping connect people to resources that will benefit them. 
          Resources like the library and community centers can also help people move up the ladder and towards hope. Meeting practical needs 
          is a way to bring the Kingdom of Heaven to earth and demonstrate Jesus’ love until he comes again.
        </p>

        <br />
        <br />

        <div className="exercise">
          <div className="exerciseOverlay"></div>

          <h1 className="centered">Excercise</h1>
        </div>

        <br />

        <div className="devText">
          Read 1 John 3:16-18. Make a list of three ways people have shown God’s love to you by meeting your needs, and then make a list of three 
          ways you can help others climb a little higher on the ladder.
        </div>

        <br />
      
      </Container>
    </div>
  </React.Fragment>
)
